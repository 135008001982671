







































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiLuckyDrawRecord,
    apiLuckyDrawGetPrizeType,
    apiLuckyDrawDetail
} from '@/api/marketing/lucky_draw'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import DatePicker from '@/components/date-picker.vue'
@Component({
    components: {
        LsPagination,
        DatePicker
    }
})
export default class LuckyDrawLog extends Vue {
    // 分页查询
    pager: RequestPaging = new RequestPaging()

    form = {
        id: 0,
        start_time: '', //
        end_time: '', //
        user_info: '', // 支持手机号、昵称、用户编号
        prize_type: '', // 奖品类型
        status: '' // 中奖状态
    }
    prizeTypeList = []

    desc = {}
    create_time = ''

    luckyDrawDetail() {
        apiLuckyDrawDetail({
            id: this.form.id
        }).then((res: any) => {
            this.desc = res
        })
    }

    luckyDrawGetPrizeType() {
        apiLuckyDrawGetPrizeType()
            .then((res: any) => {
                this.prizeTypeList = res
            })
            .catch((err: any) => {})
    }

    query(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiLuckyDrawRecord,
                params: {
                    ...this.form
                }
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载')
            })
    }

    onReset() {
        this.form.start_time = ''
        this.form.end_time = ''
        this.form.user_info = ''
        this.form.prize_type = ''
        this.form.status = ''
        this.query()
    }

    created() {
        const query: any = this.$route.query
        this.form.id = query.id
        this.query()
        this.luckyDrawGetPrizeType()
        this.luckyDrawDetail()
        this.create_time = query.create_time
    }
}
